import React from 'react';
import { Card } from '../../components';

const ConstFunctionComponent = (): JSX.Element => {
  return <div>ConstFunctionComponent</div>;
};

function FunctionComponent(): JSX.Element {
  return <div>FunctionComponent</div>;
}

class ClassComponent extends React.Component<any, any> {
  public render(): JSX.Element {
    return <div>ClassComponent</div>;
  }
}

class ClassPureComponent extends React.PureComponent<any, any> {
  public render(): JSX.Element {
    return <div>ClassPureComponent</div>;
  }
}

export function HomePage(): JSX.Element {
  return (
    <Card header={<h1>Home View</h1>}>
      <ConstFunctionComponent />
      <FunctionComponent />
      <ClassComponent />
      <ClassPureComponent />
    </Card>
  );
}
